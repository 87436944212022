@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #F4F4F4;
    font-family: Tomorrow, sans-serif;
    font-weight: 400;
}

.title {
    @apply text-xl md:text-2xl lg:text-3xl xl:text-4xl leading-snug md:leading-snug lg:leading-snug xl:leading-snug font-semibold
}

.title-lg {
    @apply text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-snug md:leading-snug lg:leading-snug xl:leading-snug font-semibold
}

.title-xl {
    @apply text-3xl md:text-4xl lg:text-5xl xl:text-6xl leading-snug md:leading-snug lg:leading-snug xl:leading-snug font-semibold
}

.subtitle {
    @apply text-lg md:text-xl lg:text-2xl xl:text-3xl leading-snug md:leading-snug lg:leading-snug xl:leading-snug
}

.heading {
    @apply text-base md:text-lg lg:text-xl xl:text-2xl leading-snug md:leading-snug lg:leading-snug xl:leading-snug
}

.body {
    @apply text-sm md:text-base lg:text-lg xl:text-xl leading-snug md:leading-snug lg:leading-snug xl:leading-snug font-light
}

.btn {
    @apply px-4 py-3 rounded hover:backdrop-contrast-100 bg-gradient-to-r from-primary to-secondary items-center justify-center text-white text-sm md:text-base shadow font-bold
}

.btn-lg {
    @apply px-6 py-4 text-base md:text-lg
}

.btn-outline {
    @apply from-transparent to-transparent text-primary border border-2 border-primary
}

.btn-clear {
    @apply from-transparent to-transparent text-primary border-none shadow-none
}

.btn-twitter {
    @apply from-twitter to-twitter
}

.btn-discord {
    @apply from-discord to-discord
}

.input {
    @apply w-full border border-white rounded leading-tight py-2 px-3 shadow-light
}

.input-lg {
    @apply rounded-lg text-lg py-4 px-6
}
